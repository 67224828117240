<template>
  <div class="sales-chart chart">
    <pie-chart :data="chartData.items" :library="library" :colors="chartData.colors"></pie-chart>
    <Spacer num="2" />
  </div>
</template>

<script>
export default {
  props: ["statistics", "bags"],
  data: function() {
    return {
      library: {
        is3D: true,
        pieHole: 0.4,
        pieSliceText: "both",
        pieSliceTextStyle: {
          color: "white",
          fontName: "Ubuntu Bold",
          fontSize: 14,
        },
        legend: {
          position: "bottom",
          textStyle: {
            fontSize: 13,
            fontName: "Ubuntu Bold",
          },
        },
      },
    };
  },
  computed: {
    chartData: function() {
      let [items, colors, _this, lang] = [{}, [], this, this.$locale];
      this.bags.forEach(function(item, index) {
        items[`${lang.words["bags"]} ${lang.words["of"]} ${item.obtain} (${item.numBags})`] = item.numBags;
        colors.push(item.color || "#bd0909");
      });
      return { items, colors };
    },
  },
};
</script>

<style lang="scss">
.sales-chart {
  position: relative;
  background-color: #fff;
  @include shadow;
  border-radius: $mpadding/2;
  svg {
    > rect {
      fill: rgba(255, 255, 255, 0);
    }
  }
}
*.__loading {
  .sales-chart {
    svg g path {
      fill: #f3f3f3;
      stroke: #f3f3f3;
      animation: ItermitFill 6s ease infinite alternate;
    }
    svg g text[text-anchor] {
      fill: #f3f3f300;
      background-color: #f3f3f3;
    }
  }
}
@include ItermitFill(#f3f3f3, #fcfcfc);
</style>
